"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MotionVisualizationSyncer = void 0;
class MotionVisualizationSyncer {
    constructor() {
        this.syncList = [];
    }
    add(mv) {
        this.syncList.push(mv);
    }
    sync() {
        Promise.all(this.syncList.map(instance => instance.ready)).then(() => {
            let firstAction = null;
            this.syncList.forEach(instance => {
                for (const action of Object.values(instance.actions)) {
                    if (!firstAction) {
                        firstAction = action;
                    }
                    action.syncWith(firstAction);
                }
            });
        });
    }
}
exports.MotionVisualizationSyncer = MotionVisualizationSyncer;
