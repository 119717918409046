"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reactor = exports.Event = void 0;
class Event {
    constructor(name) {
        this.name = name;
        this.callbacks = [];
    }
    registerCallback(callback) {
        this.callbacks.push(callback);
    }
}
exports.Event = Event;
class Reactor {
    constructor() {
        this.events = {};
    }
    registerEvent(eventName) {
        this.events[eventName] = new Event(eventName);
    }
    dispatchEvent(eventName, eventArgs) {
        if (this.events[eventName]) {
            this.events[eventName].callbacks.forEach((callback) => {
                callback(eventArgs);
            });
        }
        else {
            // Handle the case where the event is not registered
            console.warn(`Event "${eventName}" not registered.`);
        }
    }
    addEventListener(eventName, callback) {
        if (this.events[eventName]) {
            this.events[eventName].registerCallback(callback);
        }
        else {
            // Handle the case where the event is not registered
            console.warn(`Event "${eventName}" not registered.`);
        }
    }
}
exports.Reactor = Reactor;
