"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaybackController = exports.getQueryVariable = void 0;
const timer_1 = require("./timer");
function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
}
exports.getQueryVariable = getQueryVariable;
class PlaybackController {
    constructor(motionVis) {
        this.motionVis = motionVis;
        this.timelineElement = new timer_1.TimelineElement(motionVis);
        this.timelineElement.reactor.addEventListener("sweepRequest", (position) => this.onSweep(position));
        this.motionVis.reactor.addEventListener("step", () => this.onStep());
    }
    onSweep(position) {
        this.motionVis.sweep(position);
    }
    onStep() {
        if (this.timelineElement && this.motionVis.isEverythingLoadedAndReady()) {
            this.timelineElement.update(this.motionVis.progress());
        }
    }
    getCurrentSceneTimestamp() {
        return this.motionVis.getCurrentTimestamp();
    }
    playPause() {
        this.motionVis.pauseContinue();
    }
}
exports.PlaybackController = PlaybackController;
