"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const MotionVisualization_1 = require("./MotionVisualization");
const MotionVisualizationSyncer_1 = require("./MotionVisualizationSyncer");
function getQueryVariable(variable, defaultDataset) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return defaultDataset;
}
function initializeApp() {
    const syncer = new MotionVisualizationSyncer_1.MotionVisualizationSyncer();
    Array.from(document.getElementsByClassName('motion-player')).forEach(dom => {
        const mv = new MotionVisualization_1.MotionVisualization(dom);
        if (dom.classList.contains("motion-player-sync")) {
            syncer.add(mv);
        }
    });
    syncer.sync();
}
document.addEventListener('DOMContentLoaded', initializeApp);
