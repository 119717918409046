"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimerElement = exports.TimelineElement = exports.BaseElement = void 0;
const events_1 = require("./events");
class BaseElement {
    constructor(dom) {
        this.reactor = new events_1.Reactor();
        this.dom = dom;
    }
}
exports.BaseElement = BaseElement;
class TimelineElement extends BaseElement {
    constructor(motionVis) {
        let dom = document.createElement("div");
        dom.classList.add("progress");
        super(dom);
        this.dom = dom;
        this.progressBar = document.createElement("div");
        this.progressBar.classList.add("progress-bar");
        this.dom.appendChild(this.progressBar);
        motionVis.playerDom.appendChild(this.dom);
        this.reactor.registerEvent('sweepRequest');
        this.dom.addEventListener('click', (event) => {
            var _a;
            const bounds = this.dom.getBoundingClientRect();
            // Make sure to check if pageX is defined on the event, or use clientX as a fallback
            const pos = ((_a = event.pageX) !== null && _a !== void 0 ? _a : event.clientX) - bounds.left; // Position cursor
            const requestedPosition = pos / bounds.width; // Round %
            this.reactor.dispatchEvent("sweepRequest", requestedPosition);
        });
    }
    update(progress) {
        this.progressBar.style.width = progress * 100 + "%";
    }
}
exports.TimelineElement = TimelineElement;
class TimerElement extends BaseElement {
    update(datetime) {
        this.dom.innerHTML = datetime.toLocaleTimeString("de-DE");
    }
}
exports.TimerElement = TimerElement;
